import { Injectable } from '@angular/core';
import { Config } from '../app.config';
import { LocationService } from '../location/location.service';

export enum TagMode {
    AUTO, // Page views are tagged automatically
    MANUAL, // Page tags page views when ready
}

export type TagPageViewProperties = {
    partnerName?: string;
};

@Injectable()
export class TagService {
    private referrer?: string;
    private mode: TagMode = TagMode.AUTO;

    constructor(private locationService: LocationService) {}

    /**
     * Set mode to TagMode.MANUAL in page component's constructor if your page has
     * additional information for page_view tag. After loading use .tagPageView
     * with your additional information, which will automatically reset the mode
     * to TagMode.AUTO for the next page without manual tagging.
     * @param mode
     */
    public setMode(mode: TagMode) {
        this.mode = mode;
    }

    public loadGTM() {
        const domain: string = this.locationService.getDomain();
        const gtmConfig = Config.gtmId;

        if (!gtmConfig) return;

        const gtmId = gtmConfig[domain];

        if (!gtmId) return;
        const head = document.head;
        const script = document.createElement('script');
        script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
        new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
        j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
        'https://gasdat.gastivo.de/media.js?id='+i+dl;f.parentNode.insertBefore(j,f);
        })(window,document,'script','dataLayer','${gtmId}');`;
        script.async = true;
        script.defer = true;
        head.appendChild(script);
    }

    public autoTagPageView() {
        if (this.mode != TagMode.AUTO) {
            return;
        }

        const url = window.location.href;
        if (/[?&]embedded=true(&|$)/.test(url)) {
            return;
        }

        this.tagPageView();
    }

    public tagPageView(options: TagPageViewProperties = {}) {
        this.tagPageViewNow(options);
    }

    public tagPageViewNow(options: TagPageViewProperties = {}) {
        // Reset mode
        this.setMode(TagMode.AUTO);

        const dataLayer = (window as any).dataLayer;
        if (dataLayer == undefined) {
            return;
        }

        const url = window.location.href;

        if (this.referrer === url) {
            // already tagged this view
            return;
        }
        if (!this.referrer) {
            this.referrer = document.referrer;
        }

        const content_group = 'Onboarding';

        const page_location = url;
        const page_referrer = this.referrer !== url ? this.referrer : undefined;

        const user_language = this.locationService.getCurrentLanguage().shortName;
        const user_is_logged_in = 'no';
        const user_is_internal = /[?&]internal=true(&|$)/.test(url) ? 'yes' : 'no';

        const fullEvent = {
            event: 'page_view',
            tech_stack: 'Portal Alt',
            content_group,
            page_location,
            page_referrer,
            user_language,
            user_is_logged_in,
            user_is_internal,
        };

        const event: Partial<typeof fullEvent> = this.removeNullAndUndefinedValues(fullEvent);
        dataLayer.push(event);

        this.referrer = url;
    }

    private removeNullAndUndefinedValues(obj: Record<string, any>) {
        return Object.keys(obj).reduce(
            (acc, key) => {
                if (obj[key] !== null && obj[key] !== undefined) {
                    acc[key] = obj[key];
                }
                return acc;
            },
            {} as Record<string, any>,
        );
    }
}
