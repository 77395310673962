import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Angulartics2 } from 'angulartics2';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { EasyRegistrationService, EasyRegistrationData, Salutation, Country } from '../../../client-user/index';
import { MoreValidators } from '../helper/validators.lib';
import { WeFormGroup, SelectOption } from '../models';
import { FormControl, Validators } from '@angular/forms';
import { ErrorService, FormErrorValue, jumpToFirstError } from '../services/error-handle.service';
import { MetadataService } from '../services/metadata.service';
import { SupplierInfoService } from '../services/supplier-info.service';
import { LocationService } from '../location/location.service';
import { toUnicode } from 'punycode';

/**
 * This component renders the easy registration page: https://easy.gastivo.biz/go/code
 */
@Component({
    selector: 'easy-registration',
    templateUrl: 'easy-registration.html',
})
export class EasyRegistrationComponent implements OnInit {
    /**
     * The easy registration form
     */
    registrationForm: WeFormGroup;
    /**
     * The opt in form
     */
    optInForm: WeFormGroup;
    /**
     * Indicates if user registration was successful
     */
    registrationSuccess: boolean = false;
    /**
     * Indicates if the user refuses to register
     */
    registrationDeclined: boolean = false;
    /**
     * Indicates if the page is fully loaded
     */
    ready: boolean = false;
    /**
     * The form errors
     */
    formError: FormErrorValue = new FormErrorValue();
    /**
     * Indicates if the user has changed registration data
     */
    hasChange: boolean = false;
    /**
     * If true the form to change data will be shown
     */
    showChange: boolean = false;
    /**
     * If true, the data must be updated and the user can not simply accept the easy registration.
     */
    changeRequired: boolean = false;

    /**
     * If true, the user confirmed that the data is correct
     */
    isOk: boolean = false;
    /**
     * Indicates if the decline checkbox is checked
     */
    declineChecked: boolean = false;
    /**
     * The original easy registration data
     */
    loadedData: EasyRegistrationData;
    /**
     * The edited registration data
     */
    showData: EasyRegistrationData;
    /**
     * The easy registration code
     */
    code: string;
    /**
     * Indicates if the easy registration code was not found
     */
    codeNotFound: boolean = false;
    /**
     * Indicates if the easy registration code has already been redeemed
     */
    codeAlreadyUsed: boolean = false;
    /**
     * The supplier logo
     */
    supplierLogo: SafeResourceUrl;
    /**
     * The supplier name
     */
    supplierName: string;
    /**
     * All possible values for the salutation select box
     */
    salutationOptions: SelectOption[] = [];
    /**
     * All possible values for the country select box in businessUnit DE
     */
    countryOptionsDE: SelectOption[] = [];
    /**
     * All possible values for the country select box in businessUnit CH
     */
    countryOptionsCH: SelectOption[] = [];
    /**
     * True if the domain and business unit of the underlaying data are 'DE'
     */
    isDE: boolean = true;

    /**
     * The component's constructor
     *
     * @param easyRegistrationApi The easy registration API
     * @param errorService The error service
     * @param formBuilder
     * @param angulartics2
     * @param router
     * @param metadataService
     * @param translateService
     * @param route
     * @param domSantizer
     * @param supplierInfoService
     */
    constructor(
        private easyRegistrationApi: EasyRegistrationService,
        private errorService: ErrorService,
        private angulartics2: Angulartics2,
        private metadataService: MetadataService,
        private translateService: TranslateService,
        public route: ActivatedRoute,
        private domSantizer: DomSanitizer,
        private supplierInfoService: SupplierInfoService,
        private locationService: LocationService,
    ) {
        metadataService.setTag('robots', 'INDEX,FOLLOW');
        this.isDE = this.locationService.isDomainDE();
        this.setLocalizedOptions();
        this.translateService.onLangChange.subscribe(() => this.setLocalizedOptions());
        this.route.params.subscribe((param: any) => {
            let code = param['code'];
            if (code) {
                this.code = code;
                // if (1 === 1) {
                //     return this.initDemoData('DE');
                // }
                this.easyRegistrationApi.getEasyRegistration(this.code).subscribe(
                    (data) => {
                        this.isDE = data.businessUnit != 'CH';
                        this.loadedData = data;
                        this.showData = this.mapData(data);
                        this.supplierName = data.supplierName;
                        this.supplierLogo = this.domSantizer.bypassSecurityTrustResourceUrl(data.supplierLogo);
                        this.buildForm();
                        this.ready = true;
                        this.angulartics2.eventTrack.next({
                            action: 'Easy Registration',
                            properties: {
                                event: 'GAEvent',
                                gtmCustom: {
                                    eventCategory: 'Easy Registration',
                                    eventAction: 'Easy Registration angefangen',
                                },
                            },
                        });
                        this.translateService
                            .get(['COUNTRY_' + data.supplierCountry])
                            .subscribe((res: { [key: string]: string }) => {
                                let country = res['COUNTRY_' + data.supplierCountry];
                                let supplierString =
                                    data.supplierName +
                                    ', ' +
                                    data.supplierStreet +
                                    ', ' +
                                    data.supplierZip +
                                    ' ' +
                                    data.supplierCity +
                                    ', ' +
                                    country +
                                    ', ' +
                                    data.supplierEmail;
                                this.supplierInfoService.setSupplierString(supplierString);
                            });
                    },
                    this.errorService.buildSimpleHandler({
                        always: () => {
                            this.ready = true;
                        },
                        statusHandles: {
                            404: () => {
                                this.codeNotFound = true;
                            },
                            400: () => {
                                this.codeAlreadyUsed = true;
                            },
                        },
                    }),
                );
            }
        });
    }

    private setLocalizedOptions() {
        this.translateService
            .get(['EASY_REGISTRATION_META_TEXT', 'EASY_REGISTRATION_META_TITLE'])
            .subscribe((res: { [key: string]: string }) => {
                this.metadataService.setTitle(res['EASY_REGISTRATION_META_TITLE']);
                this.metadataService.setMetaDescription(res['EASY_REGISTRATION_META_TEXT']);
            });
        this.translateService.get(['SALUTATION_MR', 'SALUTATION_MRS']).subscribe((res: { [key: string]: string }) => {
            this.salutationOptions = [
                { label: res['SALUTATION_MR'], value: Salutation.HERR },
                { label: res['SALUTATION_MRS'], value: Salutation.FRAU },
            ];
        });
        this.translateService
            .get(['COUNTRY_DE', 'COUNTRY_LI', 'COUNTRY_CH'])
            .subscribe((res: { [key: string]: string }) => {
                this.countryOptionsDE = [{ label: res['COUNTRY_DE'], value: Country.DE }];
                this.countryOptionsCH = [
                    { label: res['COUNTRY_CH'], value: Country.CH },
                    { label: res['COUNTRY_LI'], value: Country.LI },
                ];
            });
    }

    /**
     * Builds the registration form
     */
    buildForm() {
        const countryControl = new FormControl(this.loadedData.country, Validators.required);
        let controls: { [key: string]: FormControl } = {
            email: new FormControl(this.loadedData.email, [Validators.required, MoreValidators.email()]),
            firstName: new FormControl(this.loadedData.firstName, Validators.required),
            lastName: new FormControl(this.loadedData.lastName, Validators.required),
            salutation: new FormControl(this.loadedData.salutation, Validators.required),
            name: new FormControl(this.loadedData.name, Validators.required),
            telefone: new FormControl(this.loadedData.telefone, MoreValidators.phoneNumber()),
            mobile: new FormControl(this.loadedData.mobile, MoreValidators.phoneNumber()),
            fax: new FormControl(this.loadedData.fax, MoreValidators.phoneNumber()),
            customerNumber: new FormControl(this.loadedData.customerNumber, Validators.required),
            street: new FormControl(this.loadedData.street, Validators.required),
            zip: new FormControl(this.loadedData.zip, [Validators.required, MoreValidators.zip(countryControl)]),
            city: new FormControl(this.loadedData.city, Validators.required),
            country: countryControl,
            newsletter: new FormControl(true),
        };

        this.registrationForm = new WeFormGroup(controls);
        this.registrationForm.sent = true;
        this.showChange = !this.registrationForm.valid;
        this.changeRequired = !this.registrationForm.valid;

        let optInControls = {
            optIn: new FormControl(false, Validators.requiredTrue),
            optInSupplier: new FormControl(false, Validators.requiredTrue),
        };

        this.optInForm = new WeFormGroup(optInControls);
    }

    /**
     * Builds the error handler
     */
    ngOnInit() {
        this.errorService.buildSimpleHandler();
    }

    /**
     * Handles registration form submission
     */
    submitForm() {
        this.registrationForm.sent = true;
        if (this.registrationForm.valid) {
            this.showData = this.registrationForm.value;
            this.showData.email = toUnicode(this.showData.email);
            this.isOk = true;
            this.showChange = false;
        } else {
            jumpToFirstError();
        }
    }

    /**
     * Sends the registration form data
     */
    sendData() {
        this.optInForm.sent = true;
        if (this.optInForm.valid) {
            if (this.registrationForm.valid) {
                if (this.showChange) {
                    this.showData = this.registrationForm.value;
                }
                this.registrationForm.loading = true;
                this.showData.newsletter = !!this.registrationForm.value.newsletter;
                this.showData.email = toUnicode(this.showData.email);
                let obs: Observable<{}>;
                obs = this.easyRegistrationApi.acceptChange(this.loadedData.id, this.showData);
                obs.subscribe(
                    (response) => {
                        window.scroll(0, 0);
                        this.registrationSuccess = true;
                        this.registrationForm.loading = false;
                        this.angulartics2.eventTrack.next({
                            action: 'Easy Registration',
                            properties: {
                                event: 'GAEvent',
                                gtmCustom: {
                                    eventCategory: 'Easy Registration',
                                    eventAction: 'Easy Registration abgeschlossen',
                                },
                            },
                        });
                    },
                    this.errorService.buildFormHandler(this.registrationForm, this.formError, {
                        isBody: true,
                        always: () => {
                            jumpToFirstError();
                        },
                    }),
                );
            }
        }
    }

    /**
     * Handles the decline checkbox change event
     *
     * @param event The change event
     */
    declineCheckedChange(event: boolean) {
        this.declineChecked = event;
        if (event) {
            this.optInForm.disable();
            this.registrationForm.disable();
        } else {
            this.optInForm.enable();
            this.registrationForm.enable();
        }
    }

    /**
     * Decline the registration
     */
    decline() {
        this.registrationForm.loading = true;
        this.easyRegistrationApi.decline(this.loadedData.id).subscribe((response) => {
            window.scroll(0, 0);
            this.registrationDeclined = true;
            this.registrationForm.loading = false;
            this.angulartics2.eventTrack.next({
                action: 'Easy Registration',
                properties: {
                    event: 'GAEvent',
                    gtmCustom: {
                        eventCategory: 'Easy Registration',
                        eventAction: 'Easy Registration abgelehnt',
                    },
                },
            });
        }, this.errorService.buildSimpleHandler());
    }

    getValue(input: string, isoCode: string): string {
        let result = '';
        try {
            const parsedInput = JSON.parse(input);
            result = parsedInput[isoCode.replace('_', '-')];
        } catch (error) {
            console.error('Could not parse input', input);
        }

        return result;
    }

    mapData(data: EasyRegistrationData) {
        const languageIsoCode = this.locationService.getCurrentLanguage().isoCode;

        const supplierPrivacyUrl = this.getLink(data.supplierPrivacyUrl, languageIsoCode);
        const supplierTermsUrl = this.getLink(data.supplierTermsUrl, languageIsoCode);

        return { ...data, supplierPrivacyUrl, supplierTermsUrl };
    }

    getLink(input: string, isoCode: string) {
        const fallbackLanguage = 'de_CH';
        let link = this.getValue(input, isoCode);

        if (link == '' && this.isDomainCH() && isoCode != fallbackLanguage) {
            link = this.getValue(input, fallbackLanguage); // Fallback language, this field shouldn't be empty
        }

        return link;
    }

    isDomainCH = () => this.locationService.isDomainCH();

    private initDemoData(country: string) {
        this.isDE = country === 'DE';
        this.loadedData = {
            salutation: 'FRAU',
            firstName: 'Erika',
            lastName: 'Mustermann',
            name: 'Gasthaus Mustermann',
            email: 'erika@example.com',
            street: 'Musterstraße',
            zip: this.isDE ? '01234' : '0123',
            city: 'Musterstadt',
            country: this.isDE ? 'DE' : 'CH',
            customerNumber: '00001',
            supplierName: 'Musterlieferant 01',
            supplierLogo: 'https://www.gastivo.de/wp-content/uploads/2022/03/Gastivo-Logo2x.png',
        };
        this.showData = this.mapData(this.loadedData);
        this.supplierName = this.loadedData.supplierName;
        this.supplierLogo = this.domSantizer.bypassSecurityTrustResourceUrl(this.loadedData.supplierLogo);
        this.buildForm();
        this.ready = true;
    }
}
