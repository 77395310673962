import { NgModule } from '@angular/core';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { Angulartics2Module } from 'angulartics2';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { routing } from './app.routing';
import { AppComponent } from './app.component';
import { MainNavigationComponent } from './main-navigation/main-navigation.component';
import { FooterComponent } from './footer/footer.component';
import { StartComponent } from './start/start.component';
import { EasyRegistrationComponent } from './easy-registration/easy-registration.component';
import { StaticPageComponent } from './static-page/static-page.component';
import { ErrorBlockComponent } from './error-block/error-block.component';
import { InputControlComponent } from './form-controls/input-control/input-control.component';
import { SelectControlComponent } from './form-controls/select-control/select-control.component';
import { SelectCheckControlComponent } from './form-controls/select-check-control/select-check-control.component';
import { TextareaControlComponent } from './form-controls/textarea-control/textarea-control.component';
import { TimeControlComponent } from './form-controls/time-control/time-control.component';
import { APIS as USER_APIS, BASE_PATH as BASE_PATH3 } from '../../client-user/index';
import { APIS, BASE_PATH } from '../../client-content/index';
import { URLFriendlyPipe, ShortenPipe, NL2BRPipe } from './pipes/pipes';
import { MetadataService } from './services/metadata.service';
import { ErrorService, FormError } from './services/error-handle.service';
import { CookieService } from './services/cookie.service';
import { RouteService } from './services/route.service';
import { TagService } from './services/tag.service';
import { SupplierInfoService } from './services/supplier-info.service';
import { FlashModule } from './flash/flash.module';
import { Config } from './app.config';
import { UrlSerializer } from '@angular/router';
import { CustomUrlSerializer } from './helper/custom_url_serializer';
import { FailtrakModule } from './failtrak/failtrak.module';
import { DeclineComponent } from './decline/decline.component';

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, '/frontend/i18n/');
}

@NgModule({
    declarations: [
        AppComponent,
        MainNavigationComponent,
        FooterComponent,
        StaticPageComponent,
        URLFriendlyPipe,
        ShortenPipe,
        NL2BRPipe,
        FormError,
        ErrorBlockComponent,
        InputControlComponent,
        SelectControlComponent,
        SelectCheckControlComponent,
        TextareaControlComponent,
        TimeControlComponent,
        StartComponent,
        EasyRegistrationComponent,
        DeclineComponent,
    ],
    imports: [
        BrowserModule,
        HttpClientModule,
        FailtrakModule,
        routing,
        ReactiveFormsModule,
        FormsModule,
        FlashModule,
        Angulartics2Module.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
    ],
    bootstrap: [AppComponent],
    providers: [
        [{ provide: BASE_PATH, useValue: Config.contentApiPath }, APIS],
        [{ provide: BASE_PATH3, useValue: Config.userApiPath }, USER_APIS],
        { provide: UrlSerializer, useClass: CustomUrlSerializer },
        MetadataService,
        ErrorService,
        CookieService,
        RouteService,
        SupplierInfoService,
        TagService,
    ],
})
export class AppModule {}
