import { Component, AfterContentInit, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Config } from './app.config';
import { LocationService } from './location/location.service';
import { TagService } from './services/tag.service';
import { filter } from 'rxjs';

/**
 * This component encapsulates the whole dynamic app content
 */
@Component({
    selector: 'app',
    templateUrl: 'app.html',
})
export class AppComponent implements AfterContentInit, OnInit {
    public useFailtrak = Config.userFailtrak;

    /**
     * The component's constructor
     */
    constructor(
        private router: Router,
        private translateService: TranslateService,
        private locationService: LocationService,
        private tagService: TagService,
    ) {
        this.translateService.setDefaultLang(this.locationService.defaultLocale());
        this.translateService.use(locationService.userLocale());
        this.tagService.loadGTM();
    }

    ngOnInit() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            this.tagService.autoTagPageView();
        });
    }

    /**
     * Remove loading animation
     */
    ngAfterContentInit() {
        $('body').removeClass('initializing');
    }
}
