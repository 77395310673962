
import { enableProdMode } from '@angular/core';
let base = 'gastivo.biz';
let s = 'https://api.' + base + '';
let config = {
    contentApiPath: s + '/content',
    shopApiPath: s + '/shop',
    userApiPath: s + '/user',
    solutionApiPath: s + '/solution',
    applicationName: '',
    cookieDomain: base,
    userFailtrak: false,
    failtrakPath: 'https://ft.gastivo.de',
    gtmId: {
        DE: 'GTM-5HBBRRK',
        CH: 'GTM-KV26TN7',
    },
    dev: false
}
enableProdMode();
export const Config = config;